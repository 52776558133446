import React, { useEffect } from "react";
import WhiteCover from "../../components/WhiteCover/WhiteCover";
import Career1 from "../../assets/images/career_1.png";
import Career1Small from "../../assets/images/career_1_small.png";
import Career2 from "../../assets/images/career_2.png";
import Career2Small from "../../assets/images/career_2_small.png";
import "./Careers.scss";
import Cover5 from "../../components/Cover5/Cover5";
import MeetOurTeam from "../../components/MeetOurTeam/MeetOurTeam";
import Benefits from "../../components/Benefits/Benefits";
import UpcomingOpenings from "../../components/UpcomingOpenings/UpcomingOpenings";
import HiringSection from "../../components/HiringSection/HiringSection";
import Map from "../../components/Map/Map";

const location = {
  address: '1600 Amphitheatre Parkway, Mountain View, california.',
  lat: 37.42216,
  lng: -122.08427,
};

const Careers = ({ startLoader }) => {
  useEffect(() => {
    startLoader();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="careers">
      <WhiteCover>
        <div className="d-flex flex-dir-col career-section-1">
          <span className="h1-sb">Careers at Jehovah Jireh</span>
          <span className="h3-sb">
            We are growing, and the team’s getting bigger by the day. We are
            always <br />
            on the lookout for smart and dynamic people.
          </span>
          <div className="mt-4 btn-container">
            <a
              onClick={() =>
                document.getElementById("footer").scrollIntoView({
                  behavior: "smooth",
                  block: "center",
                })
              }
              className="primary-btn-black w-auto"
            >
              Contact us
            </a>
          </div>
        </div>
      </WhiteCover>
      <WhiteCover>
        <div className="w-100 image-1">
          <img src={Career1} alt="Career" />
        </div>
        <div className="w-100 image-1 small">
          <img src={Career1Small} alt="Career" />
        </div>
      </WhiteCover>
      <Benefits />
      {/* <Map location={location} zoomLevel={17} /> */}
      {/* <Cover5 /> */}
      <WhiteCover>
        <p className="h1-sb text-align-center">Our Gallery!</p>
        <div className="w-100 image-1">
          <img src={Career2} alt="Career" />
        </div>
        <div className="w-100 image-1 small">
          <img src={Career2Small} alt="Career" />
        </div>
      </WhiteCover>
      <Map />
      <MeetOurTeam className={"pb-70"} />
      <HiringSection />
    </div>
  );
};

export default Careers;
