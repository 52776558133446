import React, { useEffect, useState } from "react";
import "./Admin.scss";
import NoEvents from "../../assets/images/no_events_created.png";
import Delete from "../../assets/images/delete.png";
import Edit from "../../assets/images/edit.png";
import TopArrow from "../../assets/images/top_arrow.png";
import DownArrow from "../../assets/images/down_arrow.png";
import Pause from "../../assets/images/pause.png";
import Unpause from "../../assets/images/unpause.png";
import { Link, useNavigate } from "react-router-dom";
import { BreadCrumb } from "./BreadCrumb";
import { api } from "../../api";
import Swal from "sweetalert2";
import ChangePassword from "../../components/ChangePassword/ChangePassword";
import { clearToken } from "../../utils";

export default function Admin() {
  const [events, setevents] = useState([]);
  const [opened, setOpened] = useState([]);
  const [showChangePassword, setShowChangePassword] = useState(false);
  const navigate = useNavigate();

  const getEvents = async () => {
    try {
      const token = localStorage.getItem("token");
      const headers = { Authorization: `Bearer ${token}` };
      const resp = await api.get("/be-event/fetch-events", { headers });

      setevents(resp.data.data);
    } catch (e) {
      if(e.response.status === 401) {
        clearToken();
      }
      Swal.fire({
        icon: "error",
        title: `Some error was encountered while fetching event list`,
        timer: 2500,
      });
    }
  };

  useEffect(() => {
    getEvents();
  }, []);

  const deleteValue = async (type, eventId, i, id) => {
    try {
      Swal.fire({
        title: "Are you sure you want to delete this event ?",
        showDenyButton: true,
        showCancelButton: true,
        confirmButtonText: "Yes",
        denyButtonText: `No`,
      }).then(async (result) => {
        /* Read more about isConfirmed, isDenied below */
        if (result.isConfirmed) {
          const token = localStorage.getItem("token");
          const headers = { Authorization: `Bearer ${token}` };

          const resp = await api.delete(
            `/be-event/delete-event/${type}/${eventId}${id ? `/${id}` : ""}`,
            { headers }
          );
          if (resp.data.success) {
            const temp = JSON.parse(JSON.stringify(events));
            if (type === "event") {
              temp.splice(i, 1);
              setevents(temp);
            } else {
              temp[i].jobs = temp[i].jobs.filter(
                (item, index) => id !== item.id
              );
              if (temp[i].jobs.length === 0) {
                temp.splice(i, 1);
              }
              setevents(temp);
            }
            Swal.fire({
              icon: "success",
              title: `${type.charAt(0).toUpperCase()}${type.slice(
                1
              )} deleted successfully !`,
              timer: 2500,
            });
            getEvents();
          }
        }
      });
    } catch (e) {
      if(e.response.status === 401) {
        clearToken();
      }
      Swal.fire({
        icon: "success",
        title: `Error while deleting !`,
        timer: 2500,
      });
    }
  };

  const pauseUnpause = async (type, id, paused) => {
    try {
      const token = localStorage.getItem("token");
      const headers = { Authorization: `Bearer ${token}` };
      const pausedValue = paused === 1 ? 0 : 1;
      const body = {
        paused: pausedValue,
      };
      const resp = await api.post(
        `/be-event/pause-unpause/${type}/${id}`,
        body,
        { headers }
      );
      if (resp.data.success) {
        Swal.fire({
          icon: "success",
          title: `${type.charAt(0).toUpperCase()}${type.slice(1)} ${
            paused === 1 ? "unpaused" : "paused"
          } successfully !`,
          timer: 2500,
        });
        getEvents();
      } else {
        Swal.fire({
          icon: "error",
          title: `Error while ${
            paused === 1 ? "unpausing" : "pausing"
          } the ${type.charAt(0).toUpperCase()}${type.slice(1)}!`,
          timer: 2500,
        });
      }
    } catch (e) {
      if(e.response.status === 401) {
        clearToken();
      }
      Swal.fire({
        icon: "error",
        title: `Some error was encountered`,
        timer: 2500,
      });
    }
  };

  const Event = ({ event, i }) => {
    const dateToDisplay = dateToYMD(new Date(event.date));
    return (
      <div className="event-item">
        <div
          className={
            "d-flex event-row justify-content-space-between cursor-pointer" +
            (!opened.includes(i) || event?.jobs.length === 0
              ? " no-border"
              : "")
          }
          onClick={(e) => {
            e.stopPropagation();
            setOpened((curr) => {
              if (curr.includes(i)) {
                return curr.filter((val) => val !== i);
              } else {
                return [...curr, i];
              }
            });
          }}
        >
          <div className=" flex-dir-col">
            <div className="name h3-sb">
              {event.event} at {event.place}
            </div>
            <div className="date h4-b">{dateToDisplay}</div>
          </div>
          <div className="icons">
            <img
              alt="Arrow"
              src={opened.includes(i) ? TopArrow : DownArrow}
              width={30}
              className="ml-4"
            />
            <img
              alt="Edit"
              src={Edit}
              onClick={() => navigate(`/admin/edit-event/${event.id}`)}
              width={25}
              className="ml-4"
            />
            {!!event.paused ? (
              <img
                alt="Unpause"
                src={Unpause}
                onClick={() => pauseUnpause("event", event.id, event.paused)}
                width={25}
                className="ml-4"
              />
            ) : (
              <img
                alt="Pause"
                src={Pause}
                onClick={() => pauseUnpause("event", event.id, event.paused)}
                width={25}
                className="ml-4"
              />
            )}
            <img
              alt="Delete"
              src={Delete}
              onClick={() => deleteValue("event", event.id, i, -1)}
              width={25}
              className="ml-4"
            />
          </div>
        </div>
        {opened.includes(i) &&
          event?.jobs?.map((job, j) => (
            <div
              key={j}
              className={
                "event-row d-flex job h3-sb justify-content-space-between" +
                (j === event.jobs.length - 1 ? " no-border" : "")
              }
            >
              <div>{job.title}</div>
              <div className="icons">
                {!!job.paused ? (
                  <img
                    alt="Unpause"
                    src={Unpause}
                    onClick={() => pauseUnpause("job", job.id, job.paused)}
                    width={25}
                    className="ml-4"
                  />
                ) : (
                  <img
                    alt="Pause"
                    src={Pause}
                    onClick={() => pauseUnpause("job", job.id, job.paused)}
                    width={25}
                    className="ml-4"
                  />
                )}
                <img
                  alt="Delete"
                  src={Delete}
                  onClick={() => deleteValue("job", event.id, i, job.id)}
                  width={25}
                  className="ml-4"
                />
              </div>
            </div>
          ))}
      </div>
    );
  };

  const showHideChangePassword = (value) => {
    setShowChangePassword(value);
    if (value) {
      document.getElementsByTagName("html")[0].classList.add("hide-overflow");
      document.getElementsByTagName("body")[0].classList.add("hide-overflow");
    } else {
      document
        .getElementsByTagName("html")[0]
        .classList.remove("hide-overflow");
      document
        .getElementsByTagName("body")[0]
        .classList.remove("hide-overflow");
    }
  };

  const dateToYMD = (date) => {
    var strArray = [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ];
    var d = date.getDate();
    var m = strArray[date.getMonth()];
    var y = date.getFullYear();
    return "" + (d <= 9 ? "0" + d : d) + " " + m + " " + y;
  };

  return (
    <>
      {showChangePassword && (
        <ChangePassword setShowChangePassword={showHideChangePassword} />
      )}
      <BreadCrumb />
      <div className="admin p-4">
        <div className="h1-sb w-100 text-align-center">All Events!</div>
        <div className="h3-sb w-100 text-align-center mt-3">
          Below is the list of all the events created by you!
        </div>
        <div className="w-100 d-flex justify-content-center">
          <Link
            className="primary-btn-black mt-4 ml-4 no-underline"
            to={"/admin/create-event"}
          >
            Add new event
          </Link>
          <button
            className="primary-btn-black mt-4 ml-4 no-underline"
            onClick={() => showHideChangePassword(true)}
          >
            Change password
          </button>
        </div>
        {events.length === 0 && (
          <div className="w-100 d-flex justify-content-center mt-4 mb-4 no-event">
            <img src={NoEvents} alt="No events" className={"mt-4"} />
          </div>
        )}
        <div className="bg-grey4 d-flex justify-content-center pt-4 pb-4 mt-4 mb-4 events-container flex-dir-col align-items-center">
          {events.length > 0 &&
            events.map((event, i) => (
              <Event i={i} event={event} key={`event-${i}`} />
            ))}
        </div>
      </div>
    </>
  );
}
