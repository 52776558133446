import "./App.scss";
import {
  Route,
  BrowserRouter as Router,
  Routes,
  Navigate,
} from "react-router-dom";
import Home from "./pages/Home/Home";
import Careers from "./pages/Careers/Careers";
import Header from "./components/Header/Header";
import Footer from "./components/Footer/Footer";
import Login from "./components/Login/Login";
import { useState } from "react";
import { AuthGuard } from "./components/AuthGuard/AuthGuard";
import Admin from "./pages/Admin/Admin";
import PageNoteFound from "./components/PageNotFound/PageNoteFound";
import CreateEvent from "./pages/CreateEvent/CreateEvent";
import EditEvent from "./pages/EditEvent/EditEvent";
import LogOut from "./components/LogOut/LogOut";
import Loader from "./components/Loader/Loader";

function App() {
  const [showLogin, setShowLogin] = useState(false);
  const [showLogout, setShowLogout] = useState(false);
  const [showLoader, setShowLoader] = useState(false);

  const startLoader = () => {
    // setShowLoader(true);
    // document.body.scrollTop = document.documentElement.scrollTop = 0;
    // document.getElementsByTagName("html")[0].classList.add("hide-overflow");
    // document.getElementsByTagName("body")[0].classList.add("hide-overflow");
    // setTimeout(() => {
    //   setShowLoader(false);
    //   document
    //     .getElementsByTagName("html")[0]
    //     .classList.remove("hide-overflow");
    //   document
    //     .getElementsByTagName("body")[0]
    //     .classList.remove("hide-overflow");
    // },2000)
  }

  const showHideLogin = (value) => {
    setShowLogin(value);
    if (value) {
      document.getElementsByTagName("html")[0].classList.add("hide-overflow");
      document.getElementsByTagName("body")[0].classList.add("hide-overflow");
    } else {
      document
        .getElementsByTagName("html")[0]
        .classList.remove("hide-overflow");
      document
        .getElementsByTagName("body")[0]
        .classList.remove("hide-overflow");
    }
  };

  const showHideLogout = (value) => {
    setShowLogout(value);
    if (value) {
      document.getElementsByTagName("html")[0].classList.add("hide-overflow");
      document.getElementsByTagName("body")[0].classList.add("hide-overflow");
    } else {
      document
        .getElementsByTagName("html")[0]
        .classList.remove("hide-overflow");
      document
        .getElementsByTagName("body")[0]
        .classList.remove("hide-overflow");
    }
  };

  return (
    <div className="App">
      <Router>
        {showLoader ? <Loader /> : null}
        <Header
          setShowLogin={showHideLogin}
          showLogin={showLogin}
          setShowLogout={showHideLogout}
        />
        {showLogin && <Login setShowLogin={showHideLogin} />}
        {showLogout && <LogOut setShowLogout={showHideLogout} />}
        <Routes>
          <Route path="/" element={<Home startLoader={startLoader} />} />
          <Route path={"/careers"} element={<Careers startLoader={startLoader} />} />
          <Route
            path={"/admin/edit-event/:id"}
            element={
              <AuthGuard>
                <EditEvent />
              </AuthGuard>
            }
          />
          <Route
            path={"/admin/create-event"}
            element={
              <AuthGuard>
                <CreateEvent />
              </AuthGuard>
            }
          />
          <Route
            path={"/admin"}
            element={
              <AuthGuard>
                <Admin />
              </AuthGuard>
            }
          />
          <Route path={"/404"} element={<PageNoteFound />} />
          <Route path="*" element={<Navigate to={"/404"} />} />
        </Routes>
        <Footer />
      </Router>
    </div>
  );
}

export default App;
