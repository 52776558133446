import React from 'react'
import './BlackCover.scss';

export default function BlackCover({children, className}) {
  return (
    <div className={'black-cover ' + className} >
        {children}
    </div>
  )
}
