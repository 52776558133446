import React, { useState } from "react";
import "./Login.scss";
import LeftArrow from "../../assets/images/left-arrow.png";
import { useNavigate } from "react-router-dom";
import { api } from "../../api";
import Swal from "sweetalert2";

export default function Login({ setShowLogin }) {
  const [username, setusername] = useState("");
  const [password, setpassword] = useState("");
  const navigate = useNavigate();

  const onLogin = async () => {
    try {
      const resp = await api.post(`/user/be-login`, { username, password });
      if (resp.data.success) {
        localStorage.setItem("isLoggedIn", true);
        localStorage.setItem("token", resp.data.token);
        navigate(`/admin`);
        setShowLogin(false);
        Swal.fire({
          icon: "success",
          title: "Hey Admin, welcome back !",
          showConfirmButton: true,
          timer: 2500,
          confirmButtonText: "Close",
        });
      } else {
        Swal.fire({
          icon: "error",
          title: "Invalid username/password !",
          showConfirmButton: true,
          timer: 2500,
          confirmButtonText: "Close",
        });
      }
    } catch (e) {
      console.log(e);
      Swal.fire({
        icon: "error",
        title: "Error while logging you in. Please try again in some time !",
        showConfirmButton: true,
        timer: 2500,
        confirmButtonText: "Close",
      });
    }
  };

  return (
    <div className="login-container">
      <div className="login">
        <img
          src={LeftArrow}
          onClick={() => setShowLogin(false)}
          className="mb-4 mt-2"
          height={25}
          alt="Left arrow"
        />
        <div className="h4-b">WELCOME BACK STAFF!</div>
        <div className="h1-sb">
          Login to your <br /> account.
        </div>
        <input
          placeholder="Enter username here"
          onChange={(e) => setusername(e.target.value)}
        />
        <input
          type={"password"}
          placeholder="Enter password here"
          onChange={(e) => setpassword(e.target.value)}
        />
        <button className="primary-btn-black mt-4 w-100" onClick={onLogin}>
          Login
        </button>
      </div>
    </div>
  );
}
