import axios from "axios";

let headers = {
  Accept: "application/json",
  "Content-Type": "application/json",
};

export const api = axios.create({
  baseURL: process.env.REACT_APP_API_BASE_URL,
  headers,
});
