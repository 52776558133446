import React from 'react'
import Logo from "../../assets/images/sitelogo_white.png";

export default function Loader() {
  return (
    <div className='loader' >
        <img src={Logo} alt="Loader" />
    </div>
  )
}
