import React from "react";
import LeftArrow from "../../assets/images/left-arrow.png";
import "../Login/Login.scss";

export default function LogOut({ setShowLogout }) {
  return (
    <div className="login-container">
      <div className="login">
        <img
          src={LeftArrow}
          onClick={() => setShowLogout(false)}
          className="mb-4 mt-2"
          height={25}
        />
        <div className="h4-b">LEAVING SO SOON?</div>
        <div className="h1-sb mb-4">Are you sure you want to Logout?</div>
        <button
          className="primary-btn-black mt-4 w-100"
          onClick={() => {
            localStorage.removeItem("isLoggedIn");
            localStorage.removeItem("token");
            window.location.pathname = "/";
          }}
        >
          Yes
        </button>
        <button
          className="primary-btn-black mt-4 w-100"
          onClick={() => setShowLogout(false)}
        >
          No
        </button>
      </div>
    </div>
  );
}
