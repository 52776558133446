import React from "react";
import TeamMember1 from "../../assets/images/team_member_1.png";
import TeamMember2 from "../../assets/images/team_member_2.png";
import TeamMember3 from "../../assets/images/team_member_3.png";
import TeamMember4 from "../../assets/images/team_member_4.png";
import WhiteCover from "../WhiteCover/WhiteCover";

const Team = [
  {
    name: "Denzil Fernandes",
    designation: "Director & Founder",
    img: TeamMember1,
  },
  {
    name: "Shantelle J Dsouza",
    designation: "HR Manager",
    img: TeamMember2,
  },
  {
    name: "Alita Fernandes",
    designation: "Admin",
    img: TeamMember3,
  },
  {
    name: "Devansh Shah",
    designation: "Business Development Manager",
    img: TeamMember4,
  },
];

export default function MeetOurTeam() {
  const Member = ({ member }) => {
    return (
      <div className="d-flex flex-dir-col black-main member-item">
        <div className="text-align-center mt-2">
          <img src={member?.img} alt="Member" height={255} width={225} />
        </div>
        <span className="text-align-center mt-3 h2-b">{member?.name}</span>
        <span className="text-align-center mt-3 h3-alt">
          {member?.designation}
        </span>
      </div>
    );
  };

  return (
    <WhiteCover
      className={"d-flex flex-dir-col mt-4 mb-4 pt-4 pb-4 pb-70 meet-team"}
    >
      <span className="h1-sb text-align-center black-main mb-4 ">
        Meet our Team!
      </span>
      <div className="d-flex members">
        {Team?.map((member, i) => (
          <Member key={`item-${i}`} member={member} />
        ))}
      </div>
    </WhiteCover>
  );
}
