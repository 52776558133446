import React, { useState } from "react";
import Swal from "sweetalert2";
import { api } from "../../api";
import LeftArrow from "../../assets/images/left-arrow.png";
import "../Login/Login.scss";

export default function ChangePassword({ setShowChangePassword }) {
  const [newPassword, setNewPassword] = useState("");
  const [newPassword2, setNewPassword2] = useState("");

  const onChangePass = async () => {
    try {
      if (!newPassword || !newPassword2) {
        Swal.fire({
          icon: "error",
          title: `Password cannot be empty !`,
          timer: 2500,
        });
        return;
      }
      const token = localStorage.getItem("token");
      const headers = { Authorization: `Bearer ${token}` };
      const body = {
        newPassword,
      };
      const resp = await api.post("/user/be-change-password", body, {
        headers,
      });
      if (resp.data.success) {
        localStorage.setItem("token", resp.data.token);
        Swal.fire({
          icon: "success",
          title: `Password changed successfully !`,
          timer: 2500,
        });
      }
    } catch (error) {
      Swal.fire({
        icon: "success",
        title: `Error while changing the password !`,
        timer: 2500,
      });
    }
  };

  return (
    <div className="login-container">
      <div className="login">
        <img
          src={LeftArrow}
          onClick={() => setShowChangePassword(false)}
          className="mb-4 mt-2"
          height={25}
          alt="Back arrow"
        />
        <div className="h4-b">Hi there, Admin!</div>
        <div className="h1-sb">Change password</div>
        <input
          name="newPassword"
          type={"password"}
          placeholder="Enter new password here"
          value={newPassword}
          onChange={(e) => setNewPassword(e.target.value)}
        />
        <input
          name="newPassword2"
          type={"password"}
          placeholder="Enter new password again here"
          value={newPassword2}
          onChange={(e) => setNewPassword2(e.target.value)}
        />
        {newPassword !== newPassword2 && (
          <span style={{ color: "red" }}>
            Password does not match in new password fields
          </span>
        )}
        <button
          disabled={newPassword !== newPassword2}
          className="primary-btn-black mt-4 w-100"
          onClick={onChangePass}
        >
          Change password
        </button>
      </div>
    </div>
  );
}
