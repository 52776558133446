import React from "react";
import "./Map.scss";
import WhiteCover from "../WhiteCover/WhiteCover";
import MapImage from "../../assets/images/map.png";
import MapImageSmall from "../../assets/images/map_small.png";
import CountUp from "react-countup";
import VisibilitySensor from "react-visibility-sensor";

const Map = () => (
  <WhiteCover className="map ptb-70">
    <h2 className="h1-sb text-align-center">
      Nationwide Service in Various Locations.
    </h2>
    <div className="content">
      <div className="left-container">
        <p className="h3-b">Our presence across</p>
        <div className="mt-4 item">
          <p className="h1-sb text-decoration-none num">
          43+
          </p>
          <p className="h3-sb title">cities</p>
        </div>
        <div className="mt-4 item">
          <p className="h1-sb text-decoration-none num">5+</p>
          <p className="h3-sb title">years of</p>
          <p className="h3-sb title">experience</p>
        </div>
      </div>
      <div className="right-container">
        <div className="w-100 image-1">
          <img src={MapImage} alt="Map" />
        </div>
        <div className="w-100 image-1 small">
          <img src={MapImageSmall} alt="Map" />
        </div>
      </div>
    </div>
  </WhiteCover>
);

export default Map;
