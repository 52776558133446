import React from "react";
import LogoLeft from "../../assets/images/logo_half_right.png";
import LogoRight from "../../assets/images/logo_half_left.png";
import LogoFull from "../../assets/images/logo_full_light.png";
import WhiteCover from "../WhiteCover/WhiteCover";

export default function AdvantagesOfWorking() {
  return (
    <WhiteCover
      className={"d-flex flex-dir-col mt-4 mb-4 pt-4 pb-4 advantages-container"}
    >
      <div className="image-left">
        <img src={LogoLeft} alt="Career" />
      </div>
      <div className="image-right">
        <img src={LogoRight} alt="Career" />
      </div>
      <div className="image-full small">
        <img src={LogoFull} alt="Career" />
      </div>
      <span className="h1-sb text-align-center black-main mb-4">
        Advantages of working with us!
      </span>
      <div className="d-flex mt-4 justify-content-space-around">
        <div className="d-flex mt-4 justify-content-space-around advantages">
          <ul>
            <li className="h3-alt fw-n">
              <span className="fw-b h3-b">Attract and retain top talent:</span>{" "}
              We focus on attracting and retaining the best applicants, even
              from outside the profession.
            </li>
            <li className="h3-alt fw-n">
              <span className="fw-b h3-b">Quality through our people:</span> Our
              success is driven by the expertise and dedication of our field
              personnel.
            </li>
            <li className="h3-alt fw-n">
              <span className="fw-b h3-b">Employee well-being:</span> We
              prioritize taking care of our employees, creating a supportive
              work environment that nurtures their growth.
            </li>
            <li className="h3-alt fw-n">
              <span className="fw-b h3-b">Professional partnership:</span>{" "}
              Choose us for a professional partnership that delivers exceptional
              service and mutual success.
            </li>
          </ul>
        </div>
      </div>
      <div className="mt-8 btn-container align-self-center">
        <a
          href="/careers"
          className="primary-btn-black w-auto text-decoration-none"
        >
          Join Us
        </a>
      </div>
    </WhiteCover>
  );
}
