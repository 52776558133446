import React, { useEffect } from "react";
import AdvantagesOfWorking from "../../components/AdvantagesOfWorking/AdvantagesOfWorking";
import Cover1 from "../../components/Cover1/Cover1";
import Cover2 from "../../components/Cover2/Cover2";
import Cover3 from "../../components/Cover3/Cover3";
import DownArrow from "../../assets/images/down_arrow2.png";
import MeetOurTeam from "../../components/MeetOurTeam/MeetOurTeam";
import OurClients from "../../components/OurClients/OurClients";
import WeBelieveInNumbers from "../../components/WeBelieveInNumbers/WeBelieveInNumbers";
import WhiteCover from "../../components/WhiteCover/WhiteCover";
import "./Home.scss";
import HiringSection from "../../components/HiringSection/HiringSection";
import Map from "../../components/Map/Map";

const Home = ({ startLoader }) => {
  useEffect(() => {
    startLoader();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="home">
      <Cover1 />
      <Cover2 />
      {/* <WhiteCover className="hiring-section">
        <p className="h2-b text-align-center p-2">
          We are actively hiring! Do you want to join our team?
        </p>
        <a href="/careers" className="primary-btn-black">
          Join Us Now
        </a>
      </WhiteCover> */}
      <Cover3 />
      <WeBelieveInNumbers />
      <AdvantagesOfWorking />
      <Map />
      <OurClients />
      {/* <Cover4 /> */}
      <MeetOurTeam />
      <HiringSection />
    </div>
  );
};

export default Home;
