import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import "./Header.scss";
import SiteLogo from "../../assets/images/headerlogo.png";
import Hamburger from "../../assets/images/hamburger.png";
import Cross from "../../assets/images/cross.png";
import { useLocation } from "react-router-dom";

const Header = ({ setShowLogin, setShowLogout }) => {
  const { pathname } = useLocation();
  const [mobileNav, setMobileNav] = useState(false);
  const [loggedIn, setloggedIn] = useState(false);

  useEffect(() => {
    const isloggedin = localStorage.getItem("isLoggedIn");
    setloggedIn(isloggedin);
  }, [pathname]);

  const onStaffLogin = (value) => {
    setShowLogin(true);
    setMobileNav(false);
    if (value) {
      document.getElementsByTagName("html")[0].classList.add("hide-overflow");
      document.getElementsByTagName("body")[0].classList.add("hide-overflow");
    } else {
      document
        .getElementsByTagName("html")[0]
        .classList.remove("hide-overflow");
      document
        .getElementsByTagName("body")[0]
        .classList.remove("hide-overflow");
    }
  };

  const onMobileLogOut = (value) => {
    setShowLogout(true);
    setMobileNav(false);
    if (value) {
      document.getElementsByTagName("html")[0].classList.add("hide-overflow");
      document.getElementsByTagName("body")[0].classList.add("hide-overflow");
    } else {
      document
        .getElementsByTagName("html")[0]
        .classList.remove("hide-overflow");
      document
        .getElementsByTagName("body")[0]
        .classList.remove("hide-overflow");
    }
  };

  const logOut = () => {
    setShowLogout(true);
  };

  const onMobileNavItemClick = () => {
    setMobileNav(false);
    document.getElementsByTagName("html")[0].classList.remove("hide-overflow");
    document.getElementsByTagName("body")[0].classList.remove("hide-overflow");
  };

  return (
    <div className="header sticky">
      <nav>
        <div className="nav-logo">
          <Link to="/" className="nav-logo">
            <img src={SiteLogo} alt="Logo" height={30} />
          </Link>
        </div>
        <div className="cursor-pointer" onClick={() => window.open('/', '_self')} >JEHOVAH JIREH SECURITY SERVICES LTD</div>
      </nav>
      <div className="mobile-nav d-flex justify-content-space-between ">
        <img src={SiteLogo} alt="Logo" height={20} />
        <div className="title cursor-pointer" onClick={() => window.open('/', '_self')} >JEHOVAH JIREH SECURITY SERVICES LTD</div>
        {/* <img
          src={Hamburger}
          height={25}
          alt="Hamburger"
          onClick={() => {
            setMobileNav(!mobileNav);
            document
              .getElementsByTagName("html")[0]
              .classList.add("hide-overflow");
            document
              .getElementsByTagName("body")[0]
              .classList.add("hide-overflow");
          }}
        /> */}
      </div>
      {mobileNav && (
        <div className="mobile-nav-content">
          <div className="nav-links d-flex flex-dir-col ">
            <div className="nav-logo d-flex border-bottom pb-4 mt-3 justify-content-space-between">
              <Link to="/" className="h3-sb">
                <img
                  src={SiteLogo}
                  height={25}
                  alt="Logo"
                  onClick={onMobileNavItemClick}
                />
              </Link>
              <img
                className="mr-2"
                src={Cross}
                height={20}
                alt="Cross"
                onClick={() => setMobileNav(!mobileNav)}
              />
            </div>
            <Link
              to="/"
              onClick={onMobileNavItemClick}
              className={
                "nav-link border-bottom pb-4 h3-sb" +
                (pathname === "/" ? " active" : "")
              }
            >
              Home
            </Link>
            <Link
              to="/careers"
              onClick={onMobileNavItemClick}
              className={
                "nav-link border-bottom pb-4 h3-sb" +
                (pathname === "/careers" ? " active" : "")
              }
            >
              Careers
            </Link>
            {/* {loggedIn && (
              <Link
                to="/admin"
                onClick={onMobileNavItemClick}
                className={
                  "nav-link border-bottom pb-4 h3-sb" +
                  (pathname.includes("/admin") ? " active" : "")
                }
              >
                Admin Panel
              </Link>
            )} */}
            {/* {loggedIn ? (
              <span
                className="nav-link border-bottom pb-4 h3-sb"
                onClick={onMobileLogOut}
              >
                Log Out
              </span>
            ) : (
              <span
                className="nav-link border-bottom pb-4 h3-sb"
                onClick={onStaffLogin}
              >
                Staff Login
              </span>
            )} */}
          </div>
        </div>
      )}
    </div>
  );
};

export default Header;
