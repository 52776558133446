import React from "react";
import CoverImage1 from "../../assets/images/cover_2_img1.png";
import CoverImageSmall from "../../assets/images/cover_2_small.png";
import "./Cover2";
import WhiteCover from "../WhiteCover/WhiteCover";

export default function Cover2() {
  return (
    <WhiteCover className={"ptb-70"}>
      <div className="cover-2">
        <p className="w-100 h1-sb white-main text-align-center m-0 p-0">About Us!</p>
        <div className="d-flex flex-dir-col left-content">
          <span className="h3-alt white-main plbr-32 mt-0 pt-0 mt-2 text-align-center">
            Welcome to Jehovah Jireh Security Services Ltd, your trusted partner in stewarding, housekeeping, and security guarding since 2018. With a strong industry reputation, we provide comprehensive security solutions for sites, events, and businesses. Our highly skilled personnel, unwavering commitment to client satisfaction, and meticulous preparations ensure exceptional service. Additionally, we offer professional housekeeping services to maintain clean and presentable premises. Trust us to secure your future. Contact us today to discuss your requirements and experience the difference of working with a reliable and dedicated team.
          </span>
        </div>
        <div className="d-flex content main-container">
          <div className="img-container img-1 w-100">
            <img src={CoverImage1} alt="Cover" style={{height: 'auto'}} />
          </div>
          <div className="img-container small">
            <img src={CoverImageSmall} alt="Cover" />
          </div>
        </div>
      </div>
    </WhiteCover>
  );
}
