import React from "react";
import Benefit1 from "../../assets/images/benefit_1.png";
import Benefit2 from "../../assets/images/benefit_2.png";
import Benefit3 from "../../assets/images/benefit_3.png";
import Benefit4 from "../../assets/images/benefit_4.png";
import Benefit5 from "../../assets/images/benefit_5.png";
import Benefit6 from "../../assets/images/benefit_6.png";
import WhiteCover from "../WhiteCover/WhiteCover";
import LogoLeft from "../../assets/images/logo_half_right.png";
import LogoRight from "../../assets/images/logo_half_left.png";
import LogoFull from "../../assets/images/logo_full_light.png";

const BenefitsData = [
  {
    title: "Great hourly rates",
    img: Benefit1,
  },
  {
    title: "Growth opportunities",
    img: Benefit2,
  },
  {
    title: "Flexible working hours",
    img: Benefit3,
  },
  {
    title: "Teamwork & Attention",
    img: Benefit4,
  },
  {
    title: "Staff feedback & Survey",
    img: Benefit5,
  },
  {
    title: "Staff Portal",
    img: Benefit6,
  },
];

export default function Benefits() {
  const BenefitItem = ({ benefit }) => {
    return (
      <div className="d-flex flex-dir-col black-main benefit-item">
        <div className="text-align-center mt-2">
          <img
            src={benefit?.img}
            alt="Benefit"
            height={145}
            width={145}
          />
        </div>
        <span className="text-align-center mt-3 h3-b">{benefit?.title}</span>
      </div>
    );
  };

  return (
    <WhiteCover className={'pb-70 pt-70'} >
      <div className="benefits-container w-100">
        <div className="h1-sb text-align-center">Benefits of working with us!</div>
        <div className="image-left">
          <img src={LogoLeft} alt="Career" />
        </div>
        <div className="image-right">
          <img src={LogoRight} alt="Career" />
        </div>
        <div className="image-full small">
          <img src={LogoFull} alt="Career" />
        </div>
        <div className="benefits">
          {BenefitsData?.map((benefit, i) => (
            <BenefitItem key={`benefit-${i}`} benefit={benefit} />
          ))}
        </div>
      </div>
    </WhiteCover>
  );
}
