import React from "react";
import WhiteCover from "../WhiteCover/WhiteCover";
import "./WeBelieveInNumbers.scss";
import CountUp from "react-countup";
import VisibilitySensor from "react-visibility-sensor";

const BussinessNumbers = [
  {
    title: "events served",
    count: 238,
  },
  {
    title: "staff on board",
    count: 196,
  },
  {
    title: "cities across UK",
    count: 43,
  },
  {
    title: "upcoming events",
    count: 27,
  },
];

export default function WeBelieveInNumbers() {
  const NumItem = ({ num }) => {
    return (
      <div className="d-flex flex-dir-col black-main number-item">
        <div className="text-align-center mt-2 count">
          <CountUp end={num.count} redraw={true} duration={4.75}>
            {({ countUpRef, start }) => (
              <VisibilitySensor onChange={start} delayedCall>
                <span ref={countUpRef} />
              </VisibilitySensor>
            )}
          </CountUp>
          +
        </div>
        <span className="text-align-center mt-3 h3-sb">{num?.title}</span>
      </div>
    );
  };

  return (
    <WhiteCover className={"d-flex flex-dir-col pr-2 numbers-container ptb-70"}>
      <p className="h1-sb black-main text-align-center">
        We Believe in Quality & Numbers!
      </p>
      <div className="d-flex numbers">
        {BussinessNumbers?.map((num, i) => (
          <NumItem key={`contact-${i}`} num={num} />
        ))}
      </div>
    </WhiteCover>
  );
}
