import React from "react";
import BlackCover from "../BlackCover/BlackCover";
import Address from "../../assets/images/address.png";
import Timings from "../../assets/images/timings.png";
import Email from "../../assets/images/email.png";
import ContactNumber from "../../assets/images/contact_number.png";
import Logo from "../../assets/images/sitelogo_white.png";
import Instagram from "../../assets/images/instagram.png";
import Fb from "../../assets/images/fb.png";
import Whatsapp from "../../assets/images/whatsapp.png";
import Linkedin from "../../assets/images/linkedin.png";
import "./Footer.scss";



const Contacts = [
  {
    title: "Address",
    img: Address,
    description: [
      "Jehovah Jireh Security Services Ltd",
      "West Drayton, London,",
      "England, United Kingdom",
    ],
  },
  {
    title: "Email",
    img: Email,
    description: ["info@jehovahjirehsecurity.com"],
  },
  {
    title: "Contact Number",
    img: ContactNumber,
    description: ["+447771441418", "+447404845308"],
  },
];

export default function Footer() {
  const ContactItem = ({ contact, index }) => {
    return (
      <div className="d-flex flex-dir-col contact-item">
        <div className="text-align-center mt-2">
          <img src={contact?.img} alt="Contact" height={35} />
        </div>
        <span className="text-align-center mt-3 h2-b">{contact?.title}</span>
        <div className="d-flex flex-dir-col">
          {contact?.description?.map((item, i) => (
            <>
            <span onClick={() => {
              if(index === 1) {
                navigator.clipboard.writeText(item);
                document.getElementById('copied').classList.add('show-copied');
                setTimeout(() => document.getElementById('copied').classList.remove('show-copied'), 1000);
              }

            }} key={`item-${i}`} className={"text-align-center mt-2 h3-alt" + (index === 1 ? ' email-id' : '')}>
              {item}
            </span>
            {index === 1 && <div id="copied" class="copied">Copied to clipboard</div>}
            </>
          ))}
        </div>
      </div>
    );
  };

  return (
    <BlackCover className={"flex-dir-col footer"}>
      <div className="d-flex flex-dir-col" id="footer">
        <div>
          <p className="h1-sb text-align-center header-text" >Contact Us!</p>
        </div>
        <div className="d-flex contacts">
          {Contacts?.map((contact, i) => (
            <ContactItem key={`contact-${i}`} index={i} contact={contact} />
          ))}
        </div>
      </div>
      <hr className="white-main w-100 mt-4 mb-4" />
      <div className="d-flex flex-dir-col mt-4 social-container">
        <div className="social d-flex align-items-center">
          <p className="h3-alt company-name ">
            <span className="grey2">©2023 </span>JEHOVAH JIREH SECURITY SERVICES LTD
          </p>
          <div className="logo-container">
            <img src={Logo} alt="Logo" height={180} />
          </div>
          <div className="d-flex">
            <a
              className="social-media-item"
              target={"_blank"}
              href="https://www.facebook.com/people/Jehovah-Jireh-Security-Services-LTD/100083683892956/"
            >
              <img src={Fb} alt="Facebook" height={20} />
            </a>
            <a
              className="social-media-item"
              target={"_blank"}
              href="https://www.instagram.com/jehovahjirehsecurity/?igshid=OGQ5ZDc2ODk2ZA%3D%3D"
            >
              <img src={Instagram} alt="Instagram" height={20} />
            </a>
            <a
              className="social-media-item"
              target={"_blank"}
              href="https://api.whatsapp.com/message/I5PU554RF4SXD1?autoload=1&app_absent=0"
            >
              <img src={Whatsapp} alt="Whatsapp" height={20} />
            </a>
            <a
              className="social-media-item"
              target={"_blank"}
              href="https://www.linkedin.com/company/security-hub2-ltd/"
            >
              <img src={Linkedin} alt="Linkedin" height={20} />
            </a>
          </div>
        </div>
        <p className="h3-alt text-align-center mt-42">
          Registered in England & Wales &nbsp; | &nbsp; Registered company No:
          13493914
        </p>
      </div>
    </BlackCover>
  );
}
