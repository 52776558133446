import React from "react";
import WhiteCover from "../WhiteCover/WhiteCover";
import Client1 from "../../assets/images/client_1.png";
import Client2 from "../../assets/images/client_2.png";
import Client3 from "../../assets/images/client_3.png";
import Client4 from "../../assets/images/client_4.png";
import Client5 from "../../assets/images/client_5.png";
import Client6 from "../../assets/images/client_6.png";
import Client7 from "../../assets/images/client_7.png";
import Client8 from "../../assets/images/client_8.png";
import Client9 from "../../assets/images/client_9.png";
import Client10 from "../../assets/images/client_10.png";
import Client11 from "../../assets/images/client_11.png";
import Client12 from "../../assets/images/client_12.png";
import Client13 from "../../assets/images/client_13.png";

import Client14 from "../../assets/images/client_14.png";
import Client15 from "../../assets/images/client_15.png";
import Client16 from "../../assets/images/client_16.png";
import Client17 from "../../assets/images/client_17.png";
import Client18 from "../../assets/images/client_18.png";
import Client19 from "../../assets/images/client_19.png";
import Client20 from "../../assets/images/client_20.png";

const IMG_HEIGHT = 105;
const IMG_CONTAINER_STYLE = "img-container-2 p-4 client";

export default function OurClients() {
  return (
    <WhiteCover className={"d-flex flex-dir-col mt-4 mb-4 pt-4 pb-4 ptb-70 our-clients"}>
      <div className="d-flex flex-dir-col justify-content-center w-100" >
        <span className="h1-sb text-align-center black-main mb-4">
          Our Clients!
        </span>
        <span className="h3-sb text-align-center black-main mb-4 pr-2 pl-2">
          Trusting us to safeguard their success. You can be our next client!
        </span>
      </div>
      <div className="d-flex our-clients justify-content-center">
        <div className={IMG_CONTAINER_STYLE}>
          <img src={Client1} alt="Client" height={IMG_HEIGHT} />
        </div>
        <div className={IMG_CONTAINER_STYLE}>
          <img src={Client2} alt="Client" height={IMG_HEIGHT} />
        </div>
        <div className={IMG_CONTAINER_STYLE}>
          <img src={Client3} alt="Client" height={IMG_HEIGHT} />
        </div>
        <div className={IMG_CONTAINER_STYLE}>
          <img src={Client4} alt="Client" height={IMG_HEIGHT} />
        </div>
        <div className={IMG_CONTAINER_STYLE}>
          <img src={Client5} alt="Client" height={IMG_HEIGHT} />
        </div>
        <div className={IMG_CONTAINER_STYLE}>
          <img src={Client6} alt="Client" height={IMG_HEIGHT} />
        </div>
        <div className={IMG_CONTAINER_STYLE}>
          <img src={Client7} alt="Client" height={IMG_HEIGHT} />
        </div>
        <div className={IMG_CONTAINER_STYLE}>
          <img src={Client8} alt="Client" height={IMG_HEIGHT} />
        </div>
        <div className={IMG_CONTAINER_STYLE}>
          <img src={Client9} alt="Client" height={IMG_HEIGHT} />
        </div>
        <div className={IMG_CONTAINER_STYLE}>
          <img src={Client10} alt="Client" height={IMG_HEIGHT} />
        </div>
        <div className={IMG_CONTAINER_STYLE}>
          <img src={Client12} alt="Client" height={IMG_HEIGHT} />
        </div>
        <div className={IMG_CONTAINER_STYLE}>
          <img src={Client13} alt="Client" height={IMG_HEIGHT} />
        </div>
        <div className={IMG_CONTAINER_STYLE}>
          <img src={Client11} alt="Client" height={IMG_HEIGHT} />
        </div>
        <div className={IMG_CONTAINER_STYLE}>
          <img src={Client14} alt="Client" height={IMG_HEIGHT} />
        </div>
        <div className={IMG_CONTAINER_STYLE}>
          <img src={Client15} alt="Client" height={IMG_HEIGHT} />
        </div>
        <div className={IMG_CONTAINER_STYLE}>
          <img src={Client16} alt="Client" height={IMG_HEIGHT} />
        </div>
        <div className={IMG_CONTAINER_STYLE}>
          <img src={Client17} alt="Client" height={IMG_HEIGHT} />
        </div>
        <div className={IMG_CONTAINER_STYLE}>
          <img src={Client18} alt="Client" height={IMG_HEIGHT} />
        </div>
        <div className={IMG_CONTAINER_STYLE}>
          <img src={Client19} alt="Client" height={IMG_HEIGHT} />
        </div>
        <div className={IMG_CONTAINER_STYLE}>
          <img src={Client20} alt="Client" height={IMG_HEIGHT} />
        </div>
      </div>
    </WhiteCover>
  );
}
