import React, { useState } from "react";
import Swal from "sweetalert2";
import { api } from "../../api";
import { clearToken } from "../../utils";
import { BreadCrumb } from "../Admin/BreadCrumb";
import "./CreateEvent.scss";

const defaultFormData = {
  event: "",
  date: "",
  place: "",
  jobs: [
    {
      title: "",
      formLink: "",
    },
  ],
};

export default function CreateEvent() {
  const [formData, setformData] = useState(defaultFormData);
  const [successMessage, setSuccessMessage] = useState("");

  const setData = (key, value) => {
    const temp = JSON.parse(JSON.stringify(formData));
    temp[key] = value;
    setformData(temp);
  };

  const setJobs = (i, key, value) => {
    console.log("i,key,value", i, key, value);
    const temp = JSON.parse(JSON.stringify(formData));
    const tempJobs = temp.jobs;
    tempJobs[i][key] = value;
    temp.jobs = tempJobs;
    console.log("temp, tempJobs", temp, tempJobs);
    setformData(temp);
  };

  const addJob = () => {
    if (formData.jobs.length === 10) return;
    const temp = JSON.parse(JSON.stringify(formData));
    temp.jobs.push({
      title: "",
      formLink: "",
    });
    setformData(temp);
  };

  const removeJob = (i) => {
    if (formData.jobs.length === 1) return;
    const temp = JSON.parse(JSON.stringify(formData));
    temp.jobs.splice(i, 1);
    setformData(temp);
  };

  const onSubmit = async (e) => {
    try {
      e.preventDefault();
      const token = localStorage.getItem("token");
      const headers = { Authorization: `Bearer ${token}` };
      const resp = await api.post("/be-event/create-event", formData, {
        headers,
      });
      if (resp.data.success) {
        setformData(defaultFormData);
        Swal.fire({
          icon: "success",
          title: "Event created successfully !",
          timer: 2500,
        });
      } else {
        Swal.fire({
          icon: "error",
          title: "Error while creating the event !",
          timer: 2500,
        });
      }
    } catch (e) {
      if(e.response.status === 401) {
        clearToken();
      }
      Swal.fire({
        icon: "error",
        title: `Some error was encountered while creating the event`,
        timer: 2500,
      });
    }
  };

  return (
    <div className="mb-4 pb-4">
      <BreadCrumb />
      <div className="create-event">
        <div className="h1-sb mb-4 mt-4">
          Fill the below form to add a new event!
        </div>
        <form className="mt-4" onSubmit={onSubmit} href="#">
          <div className="input-container">
            <label>Name of event</label>
            <input
              required
              value={formData.event}
              onChange={(e) => setData("event", e.target.value)}
              placeholder="Enter name here"
            />
          </div>
          <div className="input-container">
            <label>Date of event</label>
            <input
              required
              value={formData.date}
              onChange={(e) => setData("date", e.target.value)}
              type="date"
            />
          </div>
          <div className="input-container">
            <label>Place of event</label>
            <input
              required
              value={formData.place}
              onChange={(e) => setData("place", e.target.value)}
              placeholder="Enter place here"
            />
          </div>
          {formData.jobs?.map((_job, i) => (
            <div key={`job-${i}`} className="job-item">
              {i > 0 && (
                <span className="cross" onClick={() => removeJob(i)}>
                  &#x2716;
                </span>
              )}
              <div className="input-container">
                <label>Job Title {i + 1}</label>
                <input
                  required
                  value={formData.jobs[i].title}
                  placeholder="Add job title here"
                  onChange={(e) => setJobs(i, "title", e.target.value)}
                />
              </div>
              <div className="input-container mt-4">
                <label>Google Form link {i + 1}</label>
                <textarea
                  required
                  value={formData.jobs[i].formLink}
                  placeholder="Add form link here"
                  onChange={(e) => setJobs(i, "formLink", e.target.value)}
                  rows={3}
                />
              </div>
            </div>
          ))}
          <span
            className="mt-2 text-align-center mb-2"
            style={{ fontSize: 22, color: "green" }}
          >
            {successMessage}
          </span>
          <div className="d-flex justify-content-center mt-4 text-align-center">
            <span
              className={
                "secondary-btn-white add-job" +
                (formData.jobs.length === 10 ? " disabled" : "")
              }
              onClick={() => addJob()}
            >
              Add another job title
            </span>
          </div>
          <div className="d-flex justify-content-center">
            <button type="submit" className="primary-btn-black mt-4 add-event">
              Add event
            </button>
          </div>
        </form>
      </div>
    </div>
  );
}
