import React from "react";
import Event from "../../assets/images/event_service.png";
import Housekeeping from "../../assets/images/house_keeping_service.png";
import CorporateSecurity from "../../assets/images/corporate_service.png";
import Retail from "../../assets/images/retail_service.png";
import "./Cover3.scss";
import WhiteCover from "../WhiteCover/WhiteCover";

const Services = [
  {
    img: Event,
    description: ["Event, Festival ", "Security & Stewarding"],
  },
  {
    img: CorporateSecurity,
    description: ["Corporate", "Security"],
  },
  {
    img: Retail,
    description: ["Retail & Public", "Sector Security"],
  },
  {
    img: Housekeeping,
    description: ["Housekeeping", "& cleaning"],
  },
];

export default function Cover3() {
  const ServiceItem = ({ service }) => {
    return (
      <div className="d-flex flex-dir-col white-main service-item">
        <div className="text-align-center mt-2">
          <img src={service?.img} alt="Service" height={75} />
        </div>
        <div className="d-flex flex-dir-col">
          {service?.description?.map((item, i) => (
            <span
              key={`item-${i}`}
              className="text-align-center mt-2 h3-sb title"
            >
              {item}
            </span>
          ))}
        </div>
      </div>
    );
  };

  return (
    <WhiteCover className={"d-flex flex-dir-col pb-70"}>
      <p className="h1-sb white-main text-align-center">
        The Services we provide!
      </p>
      <div className="d-flex services">
        {Services?.map((service, i) => (
          <ServiceItem key={`service-${i}`} service={service} />
        ))}
      </div>
    </WhiteCover>
  );
}
