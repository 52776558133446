import * as React from "react";
import { useNavigate, useLocation } from "react-router-dom"

export const AuthGuard = ({ children }) => {
  const [validTab, setValidTab] = React.useState(false);
  const [loading, setLoading] = React.useState(true);
  const navigate = useNavigate();
  const location = useLocation();
  
  React.useEffect(() => {
    let unmounted = false;
    /**
     * checkAuth()
     * This function will act as a guard to redirect based on the conditions.
     */
    const checkAuth = async () => {
        var isLoggedIn = localStorage.getItem("isLoggedIn");
        if(!isLoggedIn){
            navigate('/');
        }
        !unmounted && setValidTab(true);
        !unmounted && setLoading(false);
    };

    checkAuth();

    return () => { unmounted = true };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.pathname]);

  return !loading && validTab && children;
};