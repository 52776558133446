import React from "react";
import "./Admin.scss";
import { Link, useLocation } from "react-router-dom";

export const BreadCrumb = () => {
  const { pathname } = useLocation();

  return (
    <div className="bread-crumb">
      <Link
        to={pathname !== "/admin" ? "/admin" : undefined}
        className={
          "item no-underline" + (pathname === "/admin" ? " disabled" : "")
        }
      >
        Admin Home &nbsp;{"/"}
      </Link>
      {pathname.includes("create-event") && (
        <Link
          to={
            pathname !== "/admin/create-event"
              ? "/admin/create-event"
              : undefined
          }
          className={
            "item no-underline" +
            (pathname === "/admin/create-event" ? " disabled" : "")
          }
        >
          &nbsp;Create Event
        </Link>
      )}
      {pathname.includes("edit-event") && (
        <Link className={"item no-underline disabled"}>&nbsp;Edit Event</Link>
      )}
    </div>
  );
};
