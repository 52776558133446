import React from "react";
import WhiteCover from "../WhiteCover/WhiteCover";
import DownArrow from "../../assets/images/down_arrow2.png";
import "../../pages/Home/Home.scss";

const HiringSection = () => {
  return (
    <WhiteCover className="hiring-section pt-4">
      <p className="h2-b text-align-center mt-4 white-main p-2">
        We are actively hiring! Do you want to join our team?
      </p>
      <div onClick={() =>
                document.getElementById("footer").scrollIntoView({
                  behavior: "smooth",
                  block: "center",
                })
              } className="d-flex justify-content-center align-item-center cursor-pointer" >
          <a className="h3-alt text-decoration-none" >
            Contact us now
          </a>
          <img className="ml-2" src={DownArrow} width={25} height={25} />
        </div>
      {/* <div className="mt-8 btn-container align-self-center w-100">
        <a
          href="/careers"
          className="primary-btn-black w-auto text-decoration-none"
        >
          Join Us
        </a>
      </div> */}
    </WhiteCover>
  );
};

export default HiringSection;
