import React from "react";
import SiteLogo from "../../assets/images/sitelogo.png";
import CoverImageSmall from "../../assets/images/cover_1_small.png";
import "./Cover1.scss";
import WhiteCover from "../WhiteCover/WhiteCover";

export default function Cover1() {
  return (
    <WhiteCover className={"cover-1-container align-items-center mt-4"}>
      <div className="cover-1">
        <span className="h1-sb white-main">Stewarding, Housekeeping and Security Guarding for events like never before.</span>
        <span className="h3-alt">
          Delivering the right service, at the right time!
        </span>
        <div className="mt-8 btn-container">
          <a
            onClick={() =>
              document.getElementById("footer").scrollIntoView({
                behavior: "smooth",
                block: "center",
              })
            }
            className="primary-btn-black w-auto"
          >
            Contact us
          </a>
        </div>
      </div>
      <div className="img-container">
        <img src={SiteLogo} alt="Cover" className="mt-64" />
      </div>
      <div className="img-container small">
        <img src={SiteLogo} alt="Cover" />
      </div>
    </WhiteCover>
  );
}
